function openModal(modal) {
	modal.classList.remove("is-inactive");
	document.body.style.overflow = "hidden";
}

function closeModal(modal) {
	modal.classList.add("is-inactive");
	document.body.style.overflow = "";
}

function initModal(trigger) {
	const modalId = trigger.dataset.modalTarget;
	const modalOverlay = document.querySelector(`[data-modal="${modalId}"]`);

	if (!modalOverlay) return;

	const modal = modalOverlay.querySelector(".modal__modal");
	const closeBtn = modalOverlay.querySelector("[data-modal-close]");

	trigger.addEventListener("click", () => {
		openModal(modalOverlay);
	});

	modal.addEventListener("click", (event) => {
		event.stopPropagation();
	});

	closeBtn?.addEventListener("click", () => {
		closeModal(modalOverlay);
	});

	modalOverlay.addEventListener("click", () => {
		closeModal(modalOverlay);
	});
}

window.addEventListener("keydown", (event) => {
	if (event.key === "Escape") {
		const activeModal = document.querySelector(".modal:not(.is-inactive)");
		if (activeModal) {
			closeModal(activeModal);
		}
	}
});

function init() {
	const modalTriggers = document.querySelectorAll("[data-modal-target]");
	modalTriggers.forEach(initModal);
}

init();
