const audio = document.querySelector("[data-audio]");
const audioBtn = document.querySelector("[data-audio-btn]");

function activateAudio(e) {
	audioBtn.classList.remove("is-inactive");
}

audioBtn.addEventListener("click", () => {
	audio.paused ? playAudio() : pauseAudio();
});

function playAudio() {
	audio.play();
	audioBtn.classList.add("is-playing");
	audioBtn.classList.remove("is-paused");
}

function pauseAudio() {
	audio.pause();
	audioBtn.classList.add("is-paused");
	audioBtn.classList.remove("is-playing");
}

export { activateAudio, playAudio, pauseAudio };
