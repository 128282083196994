import { activateConfetti } from "./confetti";

function isWeddingDay(date) {
	return date >= window.DATE_WEDDING;
}

if (isWeddingDay(new Date())) {
	activateConfetti();
} else {
	const interval = setInterval(() => {
		if (isWeddingDay(new Date())) {
			clearInterval(interval);
			activateConfetti();
		}
	}, 1000);
}
