const counter = ({ target, delay = 250, once }) => {
	let count = 0;
	let timeout = null;
	let isAlive = true;
	const subscriptions = new Set();

	const reset = () => {
		count = 0;
		clearTimeout(timeout);
	};

	const registerEvent = () => {
		if (!isAlive) {
			return;
		}
		count++;
		clearTimeout(timeout);

		if (count === target) {
			subscriptions.forEach((fn) => fn());
			reset();
			if (once) {
				kill();
			}
		} else {
			timeout = setTimeout(reset, delay);
		}
	};

	const subscribe = (fn) => {
		subscriptions.add(fn);

		return () => {
			subscriptions.delete(fn);
		};
	};

	const kill = () => {
		isAlive = false;
		subscriptions.clear();
	};

	return {
		registerEvent,
		subscribe,
		kill,
	};
};

export default counter;
