import confetti from "canvas-confetti";

const colors = ["#fff", "#de967d", "#eac842", "#aecd22", "#419cec"];

const myCanvas = document.createElement("canvas");

myCanvas.style.position = "fixed";
myCanvas.style.top = 0;
myCanvas.style.left = 0;
myCanvas.style.width = "100%";
myCanvas.style.height = "100%";
myCanvas.style.zIndex = 1000;
myCanvas.style.pointerEvents = "none";

document.body.appendChild(myCanvas);

const myConfetti = confetti.create(myCanvas, {
	resize: true,
	useWorker: true,
});

function frame(end) {
	myConfetti({
		particleCount: 4,
		angle: 60,
		spread: 55,
		origin: { x: 0 },
		colors,
	});

	myConfetti({
		particleCount: 4,
		angle: 120,
		spread: 55,
		origin: { x: 1 },
		colors,
	});

	if (Date.now() < end) {
		requestAnimationFrame(() => frame(end));
	}
}

const activateConfetti = () => {
	const duration = 3.5 * 1000;
	const end = Date.now() + duration;

	frame(end);
};

export { activateConfetti };
