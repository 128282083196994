import { activateFomo } from "./fomo";
import { activateAudio, playAudio } from "./audio";

import counter from "./utils/counter";

const clicksCounter = counter({
	target: 5,
	once: true,
});

clicksCounter.subscribe(() => {
	activateFomo();
	activateAudio();
	playAudio();

	document.body.removeEventListener("click", handleClick);
});

const handleClick = () => {
	clicksCounter.registerEvent();
};

document.body.addEventListener("click", handleClick);
