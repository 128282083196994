function getUniqueId() {
	return Math.random().toString(36).substr(2, 9);
}

document.addEventListener("DOMContentLoaded", (event) => {
	gsap.registerPlugin(ScrollTrigger);

	setupMainComposition();
	setupLocationItemComposition();
	setupEnterAnim();
	setupEnterAnimStaggered();
});

const ease = "power2.out";
const scrub = 4;

function setupMainComposition() {
	const duration = 3;

	gsap.to(".mainComposition__leaf", {
		scrollTrigger: {
			trigger: ".mainComposition__leaf",
			scrub,
		},
		rotation: 30,
		duration,
		ease,
	});

	gsap.to(".mainComposition__flower", {
		scrollTrigger: {
			trigger: ".mainComposition__flower",
			scrub,
		},
		rotation: 30,
		xPercent: -20,
		duration,
		ease,
	});

	gsap.to(".mainComposition__image--left", {
		scrollTrigger: {
			trigger: ".mainComposition__image--left",
			scrub,
		},
		rotation: -7,
		xPercent: -30,
		duration: 3,
		ease,
	});

	gsap.to(".mainComposition__image--right", {
		scrollTrigger: {
			trigger: ".mainComposition__image--right",
			scrub,
		},
		rotation: 7,
		xPercent: 30,
		duration,
		ease,
	});
}

function setupLocationItemComposition() {
	const locationItem1 = document.querySelector(
		".plan__locationItem:nth-child(1)"
	);
	const locationItem2 = document.querySelector(
		".plan__locationItem:nth-child(2)"
	);

	[locationItem1, locationItem2].forEach((el, idx) => {
		const flower = el.querySelector(".plan__locationItem__flower");

		const rotationFrom = idx === 0 ? -8 : 8;
		const rotationTo = idx === 0 ? 8 : -8;

		gsap.fromTo(
			flower,
			{
				rotation: rotationFrom,
			},
			{
				scrollTrigger: {
					trigger: flower,
					scrub,
				},
				rotation: rotationTo,
				duration: 3,
			}
		);
	});
}

function setupEnterAnim() {
	document.querySelectorAll("[data-anim]").forEach((el) => {
		gsap.fromTo(
			el,
			{ opacity: 0, y: 20 },
			{
				opacity: 1,
				y: 0,
				duration: 0.6,
				ease,
				scrollTrigger: {
					trigger: el,
					start: "top 80%",
					end: "bottom 20%",
					toggleActions: "play none none none",
				},
			}
		);
	});
}

function setupEnterAnimStaggered() {
	document.querySelectorAll("[data-anim-staggered]").forEach((el) => {
		const id = getUniqueId();
		el.setAttribute("data-gsap-id", id);

		const stagger = el.getAttribute("data-anim-stagger") || 0.25;
		const duration = el.getAttribute("data-anim-duration") || 0.6;

		const selector = `[data-gsap-id="${id}"] [data-anim-staggered-element]`;

		gsap.fromTo(
			selector,
			{ opacity: 0, y: 20 },
			{
				opacity: 1,
				y: 0,
				stagger,
				duration,
				ease,
				scrollTrigger: {
					trigger: selector,
					start: "top 80%",
					end: "bottom 20%",
					toggleActions: "play none none none",
				},
			}
		);
	});
}
