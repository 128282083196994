class FomoList {
	list = [
		{
			text: "João Paulo acabou de doar <span class='strong'>€270.00</span> a Catarina & Hugo",
			avatar: new URL("../img/fomo/1.png?as=webp&width=25", import.meta.url),
			name: "João Paulo",
		},
		{
			text: "João Macedo acabou de doar <span class='strong'>€350.00</span> a Catarina & Hugo",
			avatar: new URL("../img/fomo/2.png?as=webp&width=25", import.meta.url),
			name: "João Macedo",
		},
		{
			text: "Paulo Rodrigues acabou de doar <span class='strong'>€730.00</span> a Catarina & Hugo",
			avatar: new URL("../img/fomo/3.png?as=webp&width=25", import.meta.url),
			name: "Paulo Rodrigues",
		},
		{
			text: "João Rodrigues acabou de doar <span class='strong'>€290.00</span> a Catarina & Hugo",
			avatar: new URL("../img/fomo/4.png?as=webp&width=25", import.meta.url),
			name: "João Rodrigues",
		},
		{
			text: "Paulo Macedo acabou de doar <span class='strong'>€500.00</span> a Catarina & Hugo",
			avatar: new URL("../img/fomo/5.png?as=webp&width=25", import.meta.url),
			name: "Paulo Macedo",
		},
	];

	currentIndex = 0;

	getNext() {
		this.currentIndex = (this.currentIndex + 1) % this.list.length;
		return this.list[this.currentIndex];
	}
}

let isFomoActive = false;

const fomoList = new FomoList();

const $fomoCard = document.querySelector(".fomoCard");
const $fomoCardText = document.querySelector(".fomoCard__text");
const $fomoCardAvatar = document.querySelector(".fomoCard__avatar");
const $fomoCardName = document.querySelector(".fomoCard__name");

const DURATION_INITIAL_DELAY = 3000;
const DURATION_VISIBLE = 5000;
const DURATION_HIDDEN_INTERVAL = [5000, 10000];

const CLASS_VISIBLE = "is-visible";
const CLASS_HIDDEN = "is-hidden";

function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getDurationHidden() {
	return getRandomInt(...DURATION_HIDDEN_INTERVAL);
}

function updateFomoCard({ text, avatar, name }) {
	$fomoCardText.innerHTML = text;
	$fomoCardAvatar.src = avatar;
	$fomoCardName.innerHTML = name;
}

function showFomoCard() {
	$fomoCard.classList.add(CLASS_VISIBLE);
	$fomoCard.classList.remove(CLASS_HIDDEN);

	return new Promise((resolve) => {
		$fomoCard.addEventListener("transitionend", resolve, { once: true });
	});
}

function hideFomoCard() {
	$fomoCard.classList.add(CLASS_HIDDEN);
	$fomoCard.classList.remove(CLASS_VISIBLE);

	return new Promise((resolve) => {
		$fomoCard.addEventListener("transitionend", resolve, { once: true });
	});
}

function sleep(ms) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

async function triggerFomoCard() {
	updateFomoCard(fomoList.getNext());
	await showFomoCard($fomoCard);
	await sleep(DURATION_VISIBLE);
	await hideFomoCard($fomoCard);

	await sleep(getDurationHidden());
	triggerFomoCard();
}

function activateFomo() {
	if (isFomoActive) return;

	isFomoActive = true;
	setTimeout(() => {
		triggerFomoCard();
	}, DURATION_INITIAL_DELAY);
}

export { activateFomo };
